import { useState } from 'react';
import ConfirmationDialog from '../components/ConfirmDialog';

type ConfirmDialogOptions = {
  showCopyMessage?: boolean;
}

interface DialogState {
  open: boolean;
  title: string;
  message: string;
  cancel?: string;
  ok?: string;
  resolve: ((value: boolean | PromiseLike<boolean>) => void) | null;
  options?: ConfirmDialogOptions;
}

const useConfirmationDialog = (): [(title: string, message: string, cancel?: string, ok?: string, options?: ConfirmDialogOptions) => Promise<boolean>, JSX.Element] => {
  const [dialogState, setDialogState] = useState<DialogState>({
    open: false,
    title: '',
    message: '',
    resolve: null,
  });

  const confirm = (title: string, message: string, cancel?: string, ok?: string, options?: ConfirmDialogOptions) => {
    return new Promise<boolean>((resolve) => {
      setDialogState({ open: true, title, message, cancel, ok, resolve, options });
    });
  };

  const handleClose = (result: boolean) => {
    setDialogState((prevState) => {
      if (prevState.resolve) {
        prevState.resolve(result);
      }
      return { ...prevState, open: false };
    });
  };

  const dialog = (
    <ConfirmationDialog
      open={dialogState.open}
      title={dialogState.title}
      message={dialogState.message}
      cancel={dialogState.cancel}
      ok={dialogState.ok}
      onClose={handleClose}
      copyText={dialogState.options?.showCopyMessage}
    />
  );

  return [confirm, dialog];
};

export default useConfirmationDialog;