import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useStaticLocales } from '../admin/hooks/locales/use-static-locale';
import { FaCopy } from 'react-icons/fa';
import { toast } from 'react-toastify';

interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  cancel?: string;
  ok?: string;
  copyText?: boolean;
  onClose: (result: boolean) => void;
}
const handleClickInsideDialog = (event: React.MouseEvent) => {
  event.stopPropagation(); // Prevent event propagation to parent elements
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, onClose, title, message, cancel, ok, copyText }) => {

  const { sltStr } = useStaticLocales();

  return (
    <Dialog open={open} onClose={() => onClose(false)} onClick={handleClickInsideDialog}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
          {copyText && 
            <button
              title="Copy to Clipboard"
              className='btn ml-2'
              onClick={() => {
                navigator.clipboard.writeText(message).then(() => {
                  // Show snackbar on successful copy
                  toast.success("Copied");
                }).catch(err => {
                  toast.error(`Failed to copy text: ${err} `);
                });
              }}
            >
              <FaCopy></FaCopy>
            </button>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose(false)}>{cancel || sltStr({ key: 'btn-cancel' })}</Button>
        <Button variant="contained" onClick={() => onClose(true)} color="primary">
          {ok || sltStr({ key: 'btn-ok' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
