import { Enum_Translation_Type, containLowerCase } from '@cyren/common-lib';
import { capitalize, isEmpty, keys, lowerFirst, trim } from 'lodash';
import { useRecoilState } from 'recoil';
import { AppStateCookie } from '../../app/use-app-state';
import { tdFunc } from '../../report/types';
import { PaDataRepoState } from '../../report/use-data';
import { TOptions, TransConvOptions } from '../pa-prop-types';
import { Key } from '../patient-types';
import { fixLocaleMapping } from '../../gql-tools/utils/convert';
import { systemKeys } from '../../utils/models/question';

export function useTranslations({ locale, patientLocale }: { locale?: string, patientLocale?: string } = {}) {
  const [appStateCookie] = useRecoilState(AppStateCookie);

  const [dataState] = useRecoilState(PaDataRepoState);
  const { translations: locales, doctorsTerms, userNoteTrans } = dataState;

  // eslint-disable-next-line
  // console.log('locales', locales);

  const activeLocale = fixLocaleMapping(locale || appStateCookie.localeKey || 'english');

  function t(
    key?: string | null,
    tOptions: TOptions | null = {},
    convOptions: TransConvOptions = {}
  ) {
    const { cap, fallbackValue } = convOptions || {};
    const label = locales?.find((tra) => {
      return tra.locale === activeLocale && tra.key === key;
    });

    // if (key === 'q-date-1') {
    //   console.log('label', label);
    //   console.log('tOptions', tOptions);
    //   console.log('locales', locales);
    // }

    let output = label?.value;

    if (isEmpty(output) && fallbackValue !== undefined) {
      return fallbackValue || undefined;
    }

    output = output || key;

    keys(tOptions).forEach((oKey) => {
      let oVal = tOptions?.[oKey];
      if (oVal) {
        // system keys are likely to be proper nouns or phrases so don't mess with their capitalization
        if (!systemKeys.includes(oKey)) {
          oVal = lowerFirst(oVal);
        }
        output = output?.replaceAll(`{{${oKey}}}`, oVal);
      }
    });

    if (cap && !containLowerCase(output || undefined)) {
      return capitalize(output || undefined);
    }

    return output || undefined;
  }

  const td: tdFunc = (key: Key | undefined | null, convOptions: TransConvOptions = {}) => {
    const { fallbackValue } = convOptions || {};

    const docLabel = doctorsTerms?.find(
      (tra) =>
        tra.locale === 'english' && tra.key === key && tra.type === Enum_Translation_Type.Doctor
    );

    if (isEmpty(docLabel?.value) && fallbackValue != null) {
      return fallbackValue || undefined;
    }

    if (docLabel?.value) {
      return docLabel?.value;
    }

    const paLabel = locales.find((tra) => tra.locale === 'english' && tra.key === key);

    if (paLabel?.value) {
      return paLabel?.value;
    }

    return null;
  };

  const tNote: tdFunc = (note: Key | undefined | null) => {
    const noteTran = userNoteTrans?.find((tra) => trim(tra.target) === trim(note || "") &&
      patientLocale as string === tra.target_locale as string
    );

    const transNote = trim(noteTran?.output);
    if (transNote && transNote !== note) {
      return `${transNote} [Auto-translated from ${note}]`;
    }

    return note;
  };

  return [{ appStateCookie }, { tNote, t, td }] as const;
}
